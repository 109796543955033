import { useState } from 'react'

import { LinedUser, LinedMapPin, SolidUserCircled } from '@rushable/icons'
import Button from 'components/Button'
import Table from 'components/TableAndCell/Table'
import Badge from 'components/Tag/Badge'
import { SERVER_TIMEZONE } from 'data/constant'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getReceiptLinkAPI } from 'redux/brand/actions'
import { useAppSelector } from 'redux/hooks'
import { currencyFormatter } from 'utils/digit'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

interface TGiftCardDetailProps {
  data: any
  total: number
  loading: boolean
  page: number
  pageSize: number
  rightInfo: string
  onChange: (name: string, value: any) => void
}

export default function GiftCardDetail({
  data,
  total,
  loading,
  page,
  pageSize,
  rightInfo,
  onChange,
}: TGiftCardDetailProps): JSX.Element {
  const { brandId, giftCardId } = useParams<TParamTypes>()
  const { selectedLocation } = useAppSelector(state => state.location)
  const timezone = selectedLocation?.address?.timezone || SERVER_TIMEZONE

  const columns = [
    {
      key: 'type',
      name: 'TRANSACTION TYPE',
      className: 'w-[160px]',
      custom: (value: any) => {
        let statusValue = ''
        let color: any = ''
        switch (value) {
          case 'payment':
            statusValue = 'Order Payment'
            color = 'gray'
            break
          case 'top_up':
            statusValue = 'Top Up'
            color = 'blue'
            break
          case 'activation':
            statusValue = 'Activation'
            color = 'green'
            break
        }
        return <Badge color={color}>{statusValue}</Badge>
      },
    },
    {
      key: 'amount',
      name: 'USAGE',
      className: 'w-120px',
      align: 'right',
      custom: (value: any) => {
        return <span>{`${currencyFormatter(value || 0)}`}</span>
      },
    },
    {
      key: 'remaining',
      name: 'REMAINING',
      className: 'w-120px',
      align: 'right',
      custom: (value: any) => {
        return <span>{`${currencyFormatter(value || 0)}`}</span>
      },
    },
    {
      key: 'user',
      name: 'USER',
      className: 'w-40',
      tdClassName: 'text-lead',
      custom: (value: any, row: any) => {
        return (
          <>
            {value || row?.order?.customer ? (
              <div className='flex items-center text-ellipsis'>
                {row.type === 'top_up' ? (
                  <SolidUserCircled className='mr-1 text-silver' size={16} />
                ) : (
                  <LinedUser className='mr-1' size={16} />
                )}
                {value ? (
                  <>
                    {value?.first_name} {value?.last_name}
                  </>
                ) : (
                  <>
                    {row?.order?.customer?.first_name}{' '}
                    {row?.order?.customer?.last_name}
                  </>
                )}
              </div>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
    {
      key: 'reference',
      name: 'REFERENCE',
      tdClassName: 'text-lead',
      custom: (value: any, row: any) => {
        if (row.type === 'activation') {
          value = 'New Purchase'
        }

        if (row.type === 'top_up') {
          value = row.notes
        }

        return (
          <>
            {row.type === 'payment' ? (
              <div className='flex items-center'>
                <LinedMapPin className='mr-1' size={16} />
                {row?.order?.location?.name}
              </div>
            ) : (
              <div className='flex items-center'>{value}</div>
            )}
          </>
        )
      },
    },
    {
      key: 'created_at',
      name: 'DATE & TIME',
      align: 'right',
      custom: (value: string) => {
        return (
          <span className='whitespace-nowrap'>
            {parseServerTimeToLocalTime(
              value,
              timezone,
              'MM/DD/YYYY @ hh:mm A',
            )}
          </span>
        )
      },
    },
  ]

  const [invoiceLoading, setInvoiceLoading] = useState(false)

  const getReceiptLink = async () => {
    try {
      setInvoiceLoading(true)
      const res = await getReceiptLinkAPI({
        brandId: brandId,
        id: giftCardId,
      })
      if (res?.receipt_url) {
        window.open(res?.receipt_url)
      }
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    } finally {
      setInvoiceLoading(false)
    }
  }

  return (
    <>
      <Table
        loading={loading}
        isPageSet={true}
        current={page}
        containerClassName='text-sm text-ink rounded-lg border border-solid border-zinc overflow-hidden'
        columns={columns}
        data={data}
        total={total}
        resizePageSize={pageSize}
        totalText={'results'}
        rightButton={
          <>
            {rightInfo && (
              <div className='flex items-center'>
                <p className='mr-4 text-xs text-lead'>{rightInfo}</p>
                <Button
                  size='sm'
                  color='tertiary'
                  loading={invoiceLoading}
                  onClick={getReceiptLink}
                >
                  View Invoice
                </Button>
              </div>
            )}
          </>
        }
        onPageNoChange={value => onChange('page', value)}
        onPageSizeChange={value => {
          onChange('pageSize', value)
        }}
      />
    </>
  )
}
